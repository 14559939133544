import * as React from "react";
import { SignUpForm, SignUpFooter } from "@fitplan/lib/components/UserAuth/SignUpForm";
import { push } from "gatsby";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";

import PageWrapper from "../components/pageWrapper/PageWrapper";
import { SignupFormStrings } from "../components/strings/SignupFormStrings";

export interface Props {}

const SignUp: React.SFC<Props> = (props) => {
    return (
        <PageWrapper {...props}>
            <SignupFormStrings>
                <SignUpForm onComplete={(user) => {
                    push("/subscribe");
                }} />
                <SignUpFooter />
            </SignupFormStrings>
            <Polygon polygonColor="#111213" borderColor="#00ffb3" />
        </PageWrapper>
    );
};

export default SignUp;
